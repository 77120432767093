body {
  font-size: 16px;
  min-height: 100vh;
}

.padTop20 {
  padding-top: 30px;
}

.padBottom20 {
  padding-bottom: 20px;
}

.pad20 {
  padding: 20px;
}

.padLeft10 {
  padding-left: 10px;
}

.padLeft20 {
  padding-left: 20px;
}

.padBottom30 {
  padding-bottom: 30px;
}

.padlr10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.padlr20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.center {
  text-align: center;
}

.logodtm {
  width: 100vw;
  min-width: 300px;
  max-width: 500px;
  padding: 5px;
}

#execDelDialog.btn.btn-primary,
#execEditDialog.btn.btn-primary {
  width: auto;
}

.btn.btn-primary {
  background-color: #444;
  border-color: #555;
  width: 100%;
}

.btn-sm {
  margin-right: 10px;
}

.loginerror {
  padding: 5px;
  color: darkred;
}

.error {
  color: var(--bs-danger);
}

.div20 { 
  width: 20%;
}

.div60 {
  width: 60%;
}

.text {
  color: var(--bs-secondary-color);
}

.headline {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 2px;
  padding-left: 12px;
  font-size: 16px;
  color: rgb(var(--bs-secondary-color));
  border: 0;
  border-bottom: 1px solid var(--bs-tertiary-bg);
  text-align: center;
}

.userinfo {
  display: block;
  padding: 0 5px;
  width: 100%;
  font-size: 12px;
  color: rgb(var(--bs-secondary-color));
}

.userdatum {
  font-size: 12px;
  display: inline-block;
  color: rgb(var(--bs-secondary-color));
  text-align: right;
}

.navbar {
  background-color: var(--bs-border-color) !important;
  border: 0 !important;
}

.navbar-info {
  font-size: 12px;
  text-align: right;
}

.infobar {
  width: 100vw;
  min-width: 300px;
  max-width: 500px;
  margin: 0 auto;
}

.infobar-icons {
  font-size: 20px;
  padding-left: 10px;
}

.infobar-icon {
  color: var(--bs-secondary-color);
  padding-right: 20px;
}

.infobar-icon:hover {
  color: var(--bs-info-text-emphasis);
}

.nav-link:hover {
  color: var(--bs-info-text-emphasis);
}

.infotext {
  padding: 5px;
  text-align: right;
}

#online-info {
  padding-top: 10px;
  text-align: center;
  color: var(--bs-danger);
}

#error-info {
  padding-top: 10px;
  text-align: center;
  color: var(--bs-danger);
}

.content {
  height: calc(100vh - 220px);
  border-left: 1px solid var(--bs-tertiary-bg);
  border-right: 1px solid var(--bs-tertiary-bg);
  overflow: auto;
  padding: 0;
}

.buttonpane-out {
  width: 100%;
}

.buttonpane {
  width: 340px;
  margin: 0 auto;
}

.pointer {
  cursor: pointer;
}

.offcanvas-footer {
  margin-left: 10px;
}

#az-start,
#az-ende,
#proje-start,
#proje-ende,
#pause-start,
#pause-ende {
  width: 150px;
  margin: 10px;
}

#az-start,
#az-ende {
  margin-top: 0;
}

#proje-allout {
  width: 100%;
  max-width: 500px;
  font-size: 14px;
  padding: 20px;
  padding-top: 0;
  padding-bottom: 80px;
  color: var(--bs-info-text-emphasis);
}

.proje-allout-entry {
  width: 66px;
  display: inline-block;
  color: var(--bs-secondary-color);
}

.proje-von,
.proje-bis {
  display: inline-block;
  margin: 2px;  
  width: 30%;
}

.proje-projekt {
  display: inline-block;
  margin: 2px;
  width: 70%;
}

.proje-taetigkeit {
  display: inline-block;
  margin: 2px;
  width: 62%;
}

.proje-taet-edit-info {
  width: 100%;
  padding: 0;
  padding-right: 10px;
  text-align: right;
  font-size: 12px;
  color: silver;
}

#az-info,
#proje-info,
#pause-info {
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: var(--bs-info-text-emphasis);
}

#projekte-datalist,
#taetigkeit-text,
#pause-text {
  width: 320px;
  margin-top: 5px;
  margin-left: 10px;
}

#proje-dropdown {
  width: 320px;
  margin-left: 10px;
}

input::placeholder {
  font-weight: lighter;
}

.azbeg {
  width: 15%;
  padding: 2px;
  font-size: 14px;
}

.azname {
  width: 35%;
  padding: 2px;
  font-size: 14px;
}

.azfirma {
  width: 50%;
  padding: 2px;
  font-size: 14px;
}

.pausebeg {
  width: 15%;
  padding: 2px;
  font-size: 14px;
}

.pausename {
  width: 35%;
  padding: 2px;
  font-size: 14px;
}

.pausegrund {
  width: 50%;
  padding: 2px;
  font-size: 14px;
}

.silver {
  color: silver;
}

.danger {
  color: var(--bs-danger);
}

#proje-taet-text-big {
  height: 50vh;
}

#unsaveWarning {
  width: 100%;
  text-align: center;
}